<template>
    <div>
        <div v-if="false" class="ad" id="banner-ad">

        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.ad{
    width: 300px;
    height: 250px;
    margin: 20px auto;
    background: #999;
}
</style>