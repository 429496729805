export default [
    {
        name: 'Action',
        intro: 'Platformer action games take players on thrilling adventures through a series of interconnected levels. These games challenge players to navigate obstacles, defeat enemies, and collect items while utilizing platforming mechanics such as jumping, climbing, and swinging. Popular examples include classic titles like "Super Mario Bros." and "Sonic the Hedgehog," which blend fast-paced action with precise platforming controls. Platformer action games are perfect for gamers who enjoy a mix of skill-based challenges and engaging storytelling.'
    },{
        name: 'Adventure',
        intro: 'Point-and-click adventure games place players in immersive, story-driven worlds that they explore by clicking on objects and characters. These games emphasize puzzle-solving, inventory management, and narrative engagement. Players often take on the role of a protagonist who must unravel mysteries, overcome obstacles, and make critical decisions that shape the story\'s outcome. Classic examples include "Monkey Island" and "Broken Sword," while modern titles like "Grim Fandango Remastered" continue to push the genre\'s boundaries with rich storytelling and intricate puzzles. Point-and-click adventure games are perfect for gamers who love a good story and enjoy solving challenging puzzles.'
    },{
        name: 'Board',
        intro: 'Strategy board games challenge players to outwit their opponents through careful planning, resource management, and tactical decision-making. These games often feature intricate board layouts, a variety of pieces with unique abilities, and complex rule sets that require a deep understanding of the gameplay mechanics. Classic examples include "Chess" and "Go," which have stood the test of time due to their rich strategic depth. Modern strategy board games like "Risk" and "Settlers of Catan" continue to captivate players with their engaging themes, dynamic gameplay, and endless replayability. Strategy board games appeal to those who enjoy thinking several steps ahead and devising clever plans to outmaneuver their foes.'
    },{
        name: 'Boys',
        intro: 'Action adventure games cater to boys who love excitement, exploration, and a sense of accomplishment. These games often feature dynamic gameplay, challenging puzzles, and a rich narrative that draws players into a world of adventure. From classic platformers like "Super Mario Bros." to modern open-world games like "The Legend of Zelda: Breath of the Wild," action adventure games offer endless opportunities for boys to explore, fight, and solve mysteries. These games require quick reflexes, strategic thinking, and a sense of adventure, making them a perfect fit for boys who enjoy a fast-paced, immersive experience.'
    },{
        name: 'Casual',
        intro: 'Puzzle games are a staple of the casual gaming genre, appealing to players who enjoy mental challenges and a sense of accomplishment. These games often feature intricate designs, engaging mechanics, and progressively difficult levels that test players\' problem-solving skills. From classic games like "Tetris" and "Bejeweled" to modern mobile titles like "Candy Crush" and "Monument Valley," puzzle games offer endless hours of entertainment and mental stimulation. They are perfect for players who want to exercise their brains while having fun.'
    },{
        name: 'Eliminate',
        intro: 'Arcade games revolutionized entertainment in the late 20th century, with cabinets dotted across amusement parks, malls, and even bars. Titles like "Space Invaders," "Pac-Man," and "Donkey Kong" defined the genre, featuring fast-paced action, challenging gameplay, and pixelated graphics. Players often had limited lives and time to master these games, making every move crucial.'
    },{
        name: 'Fighting',
        intro: 'Traditional Fighting Games are a staple of the gaming industry, offering players the chance to engage in one-on-one battles with rivals. These games focus on hand-to-hand combat and often feature a wide variety of fighters with unique abilities and fighting styles. Players must master complex combinations of buttons to execute powerful attacks and special moves. Popular examples include the Street Fighter series and Tekken, which have become iconic for their fast-paced gameplay, intricate controls, and memorable characters.'
    },{
        name: 'Girls',
        intro: 'Fashion & Dress-Up Girls Games cater to young girls who love to express their creativity through fashion. Players can choose from a vast array of clothing items, accessories, and hairstyles to create unique outfits for their virtual characters. These games often feature interactive storytelling elements, allowing players to dress their characters for various occasions, such as school, parties, or fashion shows. Popular examples include games like Covet Fashion and Dress Up! Princess Castle, which provide endless opportunities for customization and creativity.'
    },{
        name: 'Hot',
        intro: 'Hot Games are addictive and challenging mobile game where players control a tiny bird that must flap its wings to navigate through a series of pipes. The game features simple, pixelated graphics and one-touch controls, but its difficulty level is surprisingly high. Players must time their taps perfectly to avoid crashing into the pipes, making Flappy Bird a frustrating yet rewarding experience. With its endless gameplay and retro charm, Flappy Bird has become a cult favorite among casual gamers.'
    },{
        name: 'Ldie',
        intro: ''
    },{
        name: 'Mini',
        intro: 'Welcome to Mini Games, your ultimate destination for pocket-sized gaming paradise! Our platform is dedicated to bringing you the best and brightest mini games that are perfect for a quick session on the go. Whether you\'re waiting for a bus, taking a break at work, or just looking to kill some time, Mini Games has something fun and engaging for you. From challenging puzzles and addictive arcades to relaxing simulations and quick strategy games, our diverse selection ensures that you\'ll never be bored.'
    },{
        name: 'New',
        intro: 'Welcome to New Games, the ultimate destination for gamers who crave the latest and greatest in the gaming world. Our platform is dedicated to bringing you the freshest, most innovative games as soon as they hit the market. From breathtaking adventures and epic RPGs to intense multiplayer battles and mind-bending puzzles, New Games has something for every type of gamer. With stunning graphics, immersive soundscapes, and groundbreaking gameplay mechanics, our games are designed to push the boundaries of what you thought was possible.'
    },{
        name: 'Popular',
        intro: 'Parkour, also known as freerunning, is an exhilarating and dynamic game genre that takes players on an exhilarating journey through urban landscapes. Players must navigate through obstacles such as buildings, walls, and other urban structures using a combination of running, jumping, vaulting, and rolling. The goal is to reach a destination as quickly and efficiently as possible while avoiding obstacles and staying in constant motion. Parkour games offer a unique and immersive experience that challenges players to think quickly, react instinctively, and push their physical limits. Whether you\'re a seasoned athlete or a casual gamer, Parkour games provide an exhilarating and rewarding challenge that\'s sure to keep you on the edge of your seat.'
    },{
        name: 'Puzzle',
        intro: 'Logic puzzle games challenge players to solve intricate problems using reasoning and deduction. These games often present a grid, board, or series of clues that must be analyzed to uncover hidden patterns, sequences, or correct answers. Examples include Sudoku, which requires filling a 9x9 grid with numbers so that each column, row, and 3x3 sub-grid contain the digits from 1 to 9, and Nonograms (also known as Griddlers or Picross), where players decode images by following rules about which cells to fill based on numerical clues.'
    },{
        name: 'Racing',
        intro: 'Arcade Racing Games are designed for fast-paced, thrilling action. Players typically control vehicles in various environments, such as city streets, rural roads, or even futuristic tracks. The emphasis is on speed, drifting, and performing tricks to gain points and unlock new content. Games like "Need for Speed" and "Burnout" series fall into this category. These games often feature vibrant graphics, dynamic music, and a range of customizable options for vehicles, making them appealing to players who enjoy the adrenaline rush of high-speed racing.'
    },{
        name: 'RacingGame',
        intro: 'Arcade Racing Games are designed for fast-paced, thrilling action. Players typically control vehicles in various environments, such as city streets, rural roads, or even futuristic tracks. The emphasis is on speed, drifting, and performing tricks to gain points and unlock new content. Games like "Need for Speed" and "Burnout" series fall into this category. These games often feature vibrant graphics, dynamic music, and a range of customizable options for vehicles, making them appealing to players who enjoy the adrenaline rush of high-speed racing.'
    },{
        name: 'Simulated',
        intro: 'Tycoon and Management Simulation Games place players in charge of building and managing various types of businesses. From theme parks and zoos to hotels and restaurants, these games offer a wide range of industries to explore. Players must make strategic decisions about pricing, staffing, marketing, and expansion to ensure the success of their ventures. Games like "RollerCoaster Tycoon" and "Zoo Tycoon" are popular examples. These games appeal to players who enjoy strategy, creativity, and the challenge of running a successful business.'
    },{
        name: 'Sports',
        intro: 'Realistic Sports Simulation Games offer players an immersive and authentic experience of their favorite sports. These games closely replicate the rules, mechanics, and strategies of real-world sports, providing a detailed and engaging simulation. Players can take control of individual athletes, teams, or even entire leagues, managing lineups, executing plays, and making crucial decisions that can sway the outcome of games.'
    },{
        name: 'Tower',
        intro: 'Traditional Tower Defense Games are the cornerstone of the genre, where players strategically place towers along a fixed path to defend against waves of enemy units. Titles like "Kingdom Rush," "Plants vs. Zombies," and "Fieldrunners" are classic examples of traditional tower defense. Players must upgrade their towers, choose the right combinations of tower types, and use special abilities to repel the encroaching foes. The challenge lies in balancing offense and defense, and adapting to the varying enemy types and attack patterns.'
    },{
        name: 'Place',
        intro: 'Place and Grow games offer a serene and fulfilling experience where players can cultivate their own unique worlds. By strategically placing various elements such as seeds, water, and sunlight, players watch as their creations grow and thrive. The gameplay is both relaxing and engaging, allowing players to express their creativity and see the fruits of their labor come to life. With vibrant graphics and soothing soundscapes, Place and Grow games provide an immersive and satisfying experience that\'s perfect for both casual gamers and gardening enthusiasts.'
    },{
        name: 'Classic',
        intro: 'Platform games, or platformers, involve navigating a character through a series of levels, often jumping between platforms and overcoming obstacles. Mario from "Super Mario Bros." and Sonic the Hedgehog are iconic examples. These games emphasize precision timing, agility, and problem-solving, with colorful worlds and memorable soundtracks that have become synonymous with childhood joy.'
    },{
        name: 'Parkour',
        intro: 'Parkour, also known as freerunning, is an exhilarating and dynamic game genre that takes players on an exhilarating journey through urban landscapes. Players must navigate through obstacles such as buildings, walls, and other urban structures using a combination of running, jumping, vaulting, and rolling. The goal is to reach a destination as quickly and efficiently as possible while avoiding obstacles and staying in constant motion. Parkour games offer a unique and immersive experience that challenges players to think quickly, react instinctively, and push their physical limits. Whether you\'re a seasoned athlete or a casual gamer, Parkour games provide an exhilarating and rewarding challenge that\'s sure to keep you on the edge of your seat.'
    },
]