import gemaData from '@/utils/gemedata/data'
export function getRandomNumbers(min, max, count) {
  const randomNumbers = new Set();
  
  while (randomNumbers.size < count) {
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    randomNumbers.add(randomNumber);
  }
  
  return Array.from(randomNumbers);
}

export function isMobile() {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag
}

export function _isMobile() {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}

export function shuffleArray(array) {
  array.sort(()=> Math.random() - 0.5)
  return array; // 返回打乱后的数组
}

export function getNameById(id){
  let gameInfo = gemaData.games.find(item => item.id == id)

  return gameInfo
}

export function getNameByKey(key){
  let games = gemaData.games.filter(item => item.key == key)
  if(key=='All') games = gemaData.games;
  return {
    title:key,
    games
  }
}

export function getNameBySearch(key){
  let games = gemaData.games.filter(item => item.title.indexOf(key) != -1 )

  return {
    title:key,
    games
  }
}

export function getRandomGames(str,end,len){
  let gamelist = getRandomNumbers(str,end,len);

  gamelist.forEach((item,i)=>{
      gamelist.splice(i,1,gemaData.games[item])
  })

  return gamelist;
}

export function scrollToTop(i) {
  window.scrollTo({
      top: i,
      behavior: 'smooth'
  });
}
